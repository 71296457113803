import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';


const url = `${process.env.VUE_APP_API_BASE_URL}/api/`

const notifyOpts = {
  multiLine: true,
  html: true,
  type: 'negative',
  timeout: 0,
  actions: [
    {
      label: 'Fermer', color: 'white', handler: () => { /* ... */
      }
    }
  ]
}

const formatError = (errors) => {
  let message = ''
  // if errors is a string, return it
  if (typeof errors === 'string') {
    return "Erreur inconnue, veuillez contacter l'administrateur en décrivant votre manoeuvre."
  }

  for (const [key, value] of Object.entries(errors)) {
    if(key === 'non_field_errors') {
      message += `${formatValue(value)}<br>`
    } else {
      message += `${key}: ${formatValue(value)}<br>`
    }
  }
  return message
}

const formatValue = (value) => {
  return Array.isArray(value) ? value.join(', ') : value
}

export {formatError, notifyOpts}

export function api_POST(path, data, callback = () => {
}) {
  const existingId = data instanceof FormData ? data.get('id') : data.id

  if (existingId) {
    console.log('PATCH SEND ' + path, data);
    axios.patch(ensureTrailingSlash(url + path) + existingId + '/', data)
      .then(response => {
        // Gérer la réponse en cas de succès
        callback(response.data)
      })
      .catch(error => {
        // Gérer l'erreur en cas d'échec
        console.error(error);
        Notify.create({
          ...notifyOpts,
          message: `Request error (${error.response.status}):<br>${formatError(error.response.data)}`
        })
      });

  } else {
    axios.post(ensureTrailingSlash(url + path), data)
      .then(response => {
        // Traitement de la réponse en cas de succès
        callback(response.data)
      })
      .catch(error => {
        // Gestion de l'erreur en cas d'échec
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          Notify.create({
            ...notifyOpts,
            message: `Request error (${error.response.status}):<br>${formatError(error.response.data)}`
          })

        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
          Notify.create({...notifyOpts, message: "error in request"})
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
          Notify.create({...notifyOpts, message: `Error: ${error.message}`})
        }
      });
  }
}

export function api_GET(path, callback = () => {
}) {
  // console.log('GET ' + ensureTrailingSlash(url + path));

  axios.get(ensureTrailingSlash(url + path))
    .then(response => {
      // Traitement de la réponse en cas de succès
      // console.log('GET response', path, response.data);
      callback(response.data);
    })
    .catch(error => {
      // Gestion de l'erreur en cas d'échec
      console.error(error);
    });
}

export function api_DELETE(path, callback = () => {
}) {
  console.log('DELETE ' + path, callback, url);

  axios.delete(ensureTrailingSlash(url + path))
    .then(response => {
      // Traitement de la réponse en cas de succès
      callback(response.data);
    })
    .catch(error => {
      // Gestion de l'erreur en cas d'échec
      console.error(error);
      Notify.create({
        ...notifyOpts,
        message: `Request error (${error.response.status}):<br>${formatError(error.response.data)}`
      })
    });
}


function ensureTrailingSlash(url) {
  if (!url.endsWith('/') && !url.includes("?")) {
    url += '/';
  }
  if (url.includes('dj-rest-auth/')) {
    url = url.replace('api/', '');
  }
  return url;
}
