import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL

export const network = {
  namespaced: true,
  state: {
    authToken: localStorage.getItem('authToken') || '',
  },
  mutations: {
    setAuthToken(state, key) {
      state.authToken = key
      if (key !== '')
        localStorage.setItem('authToken', key)
      else
        localStorage.removeItem('authToken')
    },
    deleteAuthToken(state) {
      state.authToken = ''
      localStorage.removeItem('authToken')
    }
  },
  actions: {
    async get(_, {url, params}) {
      try {
        return await axios.get(`${API_BASE_URL}${url}`, {
          params: params
        })
          .then(response => response.data)
      } catch (e) {
        console.log('Unable to fetch data', e.message)
        throw e
      }
    },

    async post(_, {url, data}) {
      try {
        return await axios.post(`${API_BASE_URL}${url}`, data,
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => response.data)
      } catch (e) {
        console.log('Unable to post data', e.message)
        throw e
      }
    },

    async put(_, {url, data}) {
      try {
        return await axios.put(`${API_BASE_URL}${url}`, data,
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => response.data)
      } catch (e) {
        console.log('Unable to post data', e.message)
        throw e
      }
    },

    async delete(_, {url}) {
      try {
        return await axios.delete(`${API_BASE_URL}${url}`)
          .then(response => response.data)

      } catch (e) {
        console.log('Unable to post data', e.message)
        throw e
      }
    },

    async patch(_, {url, data}) {
      try {
        return await axios.patch(`${API_BASE_URL}${url}`, data,
          {
            headers: {'Content-Type': 'application/json'}
          })
          .then(response => response.data)
      } catch (e) {
        console.log('Unable to post data', e.message)
        throw e
      }
    }
  },
  getters: {
    getFetchHeaders(state) {
      let headers = {}
      if (state.csrfToken) {
        headers['X-CSRFToken'] = state.csrfToken
      }
      if (state.authToken) {
        headers['Authorization'] = `Token ${state.authToken}`
      }
      return headers
    }
  },
}
