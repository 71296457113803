import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';
import langFr from 'quasar/lang/fr'
import quasarUserOptions from './quasar-user-options'
import router from './router'

// import JsonCSV from 'vue-json-csv'

import store from './store.js'
import storePlugin from './libs/storePlugin';

import axios from "axios";

axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'

// redirect to maintenance page if response is 503
axios.interceptors.response.use(function (response) {
  return response;

}, function (error) {
  if (error.response.status === 503) {
    router.push({ name: 'maintenance' });
  }
  return Promise.reject(error);
})

createApp(App).use(Quasar, {
  lang: langFr,
  plugins: {
    Notify,
    Dialog
  },
  config: {
    notify: quasarUserOptions
  }
})
  .use(router)
  .use(store)
  .use(storePlugin)
  // .use(JsonCSV)
  .mount('#app')
