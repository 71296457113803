<template>
	<q-dialog class="edit-zone" v-model="$store.state.message.open" transition-show="slide-up" transition-hide="slide-down" @hide="$store.state.message.open">
		<q-card>
			<q-card-section>
				<div class="text-h6">Envoyer un message</div>
			</q-card-section>
			<q-card-section class="q-pt-none">
				<q-select
					flat
					v-model="mails"
					use-input
					use-chips
					multiple
					input-debounce="0"
					@new-value="createValue"
					:options="filterOptions"
					@filter="filterFn"
				/>
				<q-input label="Sujet" v-model="$store.state.message.sujet" />
				<q-input label="Message" v-model="$store.state.message.message" type="textarea" />
			</q-card-section>
		</q-card>
	</q-dialog>
</template>
<script>
	import { ref } from 'vue'

	const stringOptions = [
        "john@example.com <Nom Antenne>",
        "mary@example.com <Nom Antenne>",
        "james@example.com <Nom Antenne>",
        "emma@example.com <Nom Antenne>",
        "alex@example.com <Nom Antenne>",
        "sarah@example.com <Nom Antenne>",
        "david@example.com <Nom Antenne>",
      ]

	export default {
		data () {
			return {
				mails : [
					'john@example.com <Nom Antenne>'
				]
			}
		},
		setup () {
			const filterOptions = ref(stringOptions)

			return {
				//mails: ref(null),
				filterOptions,

				createValue (val, done) {
					if (val.length > 0) {
						if (!stringOptions.includes(val)) {
							stringOptions.push(val)
						}
						done(val, 'toggle')
					}
				},

				filterFn (val, update) {
					update(() => {
						if (val === '') {
							filterOptions.value = stringOptions
						}
						else {
							const needle = val.toLowerCase()
							filterOptions.value = stringOptions.filter(
								v => v.toLowerCase().indexOf(needle) > -1
							)
						}
					})
				}
			}
		}
	}

</script>