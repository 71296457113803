export default {
  install: (app, options) => {
    // La fonction is_coord() utilise ici les variables du store
    app.config.globalProperties.is_coord = () => {

      console.log('is_coord', app.config.globalProperties.$store.state.user, options);
      if( app.config.globalProperties.$store.state.user )
      {
        return app.config.globalProperties.$store.state.user.is_coord;   
      }
      else
      {
        return false
      }
      
    };
  },
};
